<template>
    <div class="page-container add-payment">
        <new-payment-method-form class="md-layout-item md-size-50 md-small-size-100"></new-payment-method-form>
    </div>
</template>

<script>
    // import {mapGetters} from 'vuex'
    import NewPaymentMethodForm from './NewPaymentMethodForm'
    export default {
        name: "NewPaymentMethod",
        components: {NewPaymentMethodForm},
        data() {
            return {

            }
        },

    }
</script>

<style lang="scss" scoped>
   
</style>
