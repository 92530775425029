<template>
  <div>
    <form ref="paymentForm" id="paymentForm" @submit.prevent="createCard">
      <md-card class="creditCard md-layout-item md-size-100 md-small-size-100" :class="{'blur': createCardButton}">
        <md-card-content>
          <div class="container">

            <div class="details md-layout">
              <div class="cardNumber md-layout-item md-xsmall-size-50 md-medium-size-65 md-large-size-65">
                <label for="">Card number</label>
                <div id="card-number">
                  <!-- Stripe element will be mounted here -->
                </div>
              </div>
              <div class="cardExpiry md-layout-item md-xsmall-size-20 md-medium-size-12 md-large-size-12">
                <label for="">&nbsp;</label>
                <div id="card-expiry">
                  <!-- Stripe element will be mounted here -->
                </div>
              </div>
              <div class="cardCVC md-layout-item md-xsmall-size-20 md-medium-size-12 md-large-size-12">
                <label for="">&nbsp;</label>
                <div id="card-cvc">
                  <!-- Stripe element will be mounted here -->
                </div>
              </div>
            </div>
            <div class="card-strip"><img src="/images/gopremium/card_strip.png" /></div>
            <div class="details-container">
              <md-field :class="getValidationClass('name')" class="name">
                <label>Cardholder name</label>
                <md-input v-model="payment.name" type="text"></md-input>
                <span class="md-error" v-if="!$v.payment.name.required"><md-icon class="error-icon">error</md-icon> Name is required</span>
                <span class="md-error" v-else-if="!$v.payment.name.noNumbers"><md-icon class="error-icon">error</md-icon> Name cannot contain numbers</span>
              </md-field>


              <md-field :class="getValidationClass('address_zip')" class="zip">
                <label>ZIP code</label>
                <md-input v-model="payment.address_zip" type="text" maxlength="5"></md-input>
                <span class="md-error" v-if="!$v.payment.address_zip.required"><md-icon class="error-icon">error</md-icon> ZIP is required</span>
                <span class="md-error" v-else-if="!$v.payment.address_zip.numeric"><md-icon class="error-icon">error</md-icon> Zip must be all numbers, no letters</span>
                <span class="md-error" v-else-if="!$v.payment.address_zip.min || !$v.payment.address_zip.max"><md-icon class="error-icon">error</md-icon> Zip must be 5 characters long</span>
              </md-field>
            </div>
          </div>

          <div v-if="failedPayments && failedPayments.length">
            <p style="color: #FC3841">This card will be set as your default card, and the previous failed payment totaling ${{ failedPayments[0].amount }} will be charged. <router-link :to="{ name: 'settings.payment-receipt', params: {paymentId: failedPayments[0].id} }" target="_blank">View payment details</router-link></p>
          </div>

          <p class="error" v-if="cardError"><md-icon class="error-icon">error</md-icon> {{ cardError }}</p>

        </md-card-content>

        <md-card-actions v-if="showSubmitButton">
          <md-button :to="{name: cancelRoute}" class="md-primary">Cancel</md-button>
          <md-button class="md-raised md-primary" type="submit">Submit</md-button>
        </md-card-actions>
        <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="createCardButton"></md-progress-spinner>
      </md-card>
    </form>
    <!--        </api-create>-->
  </div>
</template>

<script>
// import {mapGetters} from 'vuex'
import { validationMixin } from 'vuelidate'
import config from '../../config.js'
import {
  required,
  minLength,
  maxLength,
  numeric,
  alpha
} from 'vuelidate/lib/validators'

import { mapActions, mapGetters } from 'vuex'

const noNumbers = (value) => {
  const hasNumber = /\d/.test(value);
  return !hasNumber
}

export default {
  name: "NewCreditCardForm",
  components: {},
  mixins: [validationMixin],
  props: {
    showSubmitButton: {type: Boolean, default: true}, // SHOW OR HIDE THE SUBMIT BUTTON
    cancelRoute: {type: String, default: 'settings.payment-methods'}, // ROUTE TO CALL WHEN CANCEL BUTTON IS CLICKED
  },
  data() {
    return {
      cardError: false,
      creditCard: null,
      createCardButton: false,
      payment: {
        name: '',
        // address_line1: '',
        // address_line2: '',
        // address_city: '',
        // address_state: '',
        address_zip: '',
        address_country: 'US'
      },
    }
  },
  computed: {
    ...mapGetters([
      'failedPayments'
    ])
  },
  validations: {
    payment: {
      name: { required, noNumbers },
      // address_line1: { required },
      // address_city: { required },
      // address_state: { required },
      address_zip: {
        required,
        min: minLength(5),
        max: maxLength(5),
        numeric
      }
    }
  },
  mounted() {
    this.stripe = Stripe(config.stripe.publicKey)
    this.elements = this.stripe.elements({
      fonts: [
        {cssSrc: 'https://fonts.googleapis.com/css?family=Roboto'}
      ],
    })
    this.stripeElements = {}
    this.mountStripe()
  },
  methods: {
    ...mapActions([
      'updateDefaultPaymentMethod',
      'retryPayment',
      'fetchCampaigns',
      'fetchSubscriptions'
    ]),
    getValidationClass (fieldName) {
      const field = this.$v.payment[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    async createCard(){
      try{
        //console.log('CREATE CARD FUNCTION CALLED');

        this.$v.$touch()
        if (this.$v.$anyError || this.$v.$invalid) {
          console.error(this.$v.anyError, this.$v.$invalid);
          return
        }
        this.createCardButton = true
        // VALIDATION COMPLETE, SUBMIT THE DATA
        let customerInfo = JSON.parse(JSON.stringify(this.payment))

        //console.log('customerInfo', customerInfo);

        //console.log('STRIPE', this.stripe);

        const result = await this.stripe.createToken(this.stripeElements.card, customerInfo);
        if(result.error){
          this.createCardButton = false
          this.cardError = result.error.message
          return;
        }

        self.cardError = false
        //console.log("Stripe createToken result - ", result);
        // Fire the cardCreated event which calls the API create

        const expireDate = new Date(result.token.card.exp_year, result.token.card.exp_month, 0);
        const formattedExpireDate = expireDate.getUTCFullYear() + '-' + (parseInt(expireDate.getUTCMonth()) + 1) + '-' + expireDate.getUTCDate();

        const createdCard = await this.$store.dispatch('createCreditCard', {
          type: result.token.card.brand,
          token: result.token.id,
          endNumbers: result.token.card.last4,
          expireDate: formattedExpireDate,
          zipCode: result.token.card.address_zip,
          nameOnCard: result.token.card.name
        })

        // console.log('CARD CREATED', JSON.parse(JSON.stringify(createdCard)))

        await this.$store.dispatch('fetchCreditCards');

        this.$store.dispatch("setGoPremiumCompleteFlag");

        if(this.failedPayments && this.failedPayments.length){
          // RETRY PAYMENT
          await this.updateDefaultPaymentMethod({paymentMethod: 'Credit Card', id: createdCard.data.id})
          await this.retryPayment(this.failedPayments[0].id)
          await this.fetchCampaigns()
          await this.fetchSubscriptions();
          return this.$router.push({name: 'dashboard.premium'})
        }

        // FIRE EVENT IN PARENT
        this.$emit('cardCreated', createdCard)

      }catch(e) {
        this.createCardButton = false
        console.error(e);
        this.cardError = e.response.data.message
      }
    },
    mountStripe() {
      var elementStyles = {
        base: {
          color: '#000000de',
          fontWeight: 500,
          fontFamily: 'Roboto, Consolas, Menlo, monospace',
          fontSize: '17px',
          lineHeight: '32px',
          borderBottom: '1px solid #ddd',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#0000008a',
            fontWeight: 300,
          },
          ':-webkit-autofill': {
            color: '#e39f48',
          },
        },
        invalid: {
          color: '#E25950',
          '::placeholder': {
            color: '#FFCCA5',
          },
        },
      }

      var elementClasses = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
      }

      var cardNumber = this.elements.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses,
      })
      cardNumber.mount('#card-number')

      var cardExpiry = this.elements.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses,
      })
      cardExpiry.mount('#card-expiry')

      var cardCvc = this.elements.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses,
      })
      cardCvc.mount('#card-cvc')

      this.stripeElements.card = cardNumber
    },
  }

}
</script>

<style lang="scss" scoped>
.creditCard{
  min-width: 620px;
}

.error{
  color:red;
  //position: absolute;
  //top: -10px;
  margin-left: -6px;
  .error-icon{
    font-size: 16px!important;
    color: #d93025!important;
  }
}
.error-icon{
  font-size: 16px!important;
  color: #d93025!important;
}
.md-error{
  margin-left: -6px;
}
#paymentForm p{
  margin-bottom: 20px;
}
.md-card-actions{
  display: block;
  text-align: right;
  padding-top: 20px;
  clear: both;
  padding-bottom: 20px!important;
}
.spinner{
  top:50%;
  margin-top: -50px;
  left: 50%;
  margin-left: -40px;
  position: absolute;
}
.blur{
  filter: blur(1px);
}
label{
  color: #666;
}
.details{
  margin-bottom: 8px;
}
.cardNumber{
  border-bottom: 1px solid #a1a1a1;
  float: left;
  width: 68%;
  margin-bottom: 4px;
  margin-top: 0px;

}
.cardExpiry{
  border-bottom: 1px solid #a1a1a1;
  float: left;
  width: 13%;
  margin-left: 24px;
  margin-bottom: 4px;
  margin-top: 22px;
}
.cardCVC{
  border-bottom: 1px solid #a1a1a1;
  float: left;
  width: 10%;
  margin-left: 24px;
  margin-bottom: 4px;
  margin-top: 22px;
}

.card-strip{
  margin-left: -5px;
  margin-top: 0px;
}

.zip{
  margin-top: -8px;
}
.md-card-actions{
  margin-top: 14px;
}

.details-container{

  .name{
    margin-top: 20px;
    width: 48%;
    float: left;
    margin-right: 4%;
  }
  .zip{
    margin-top: 20px;
    width: 48%;
    float: left;
  }
}
p{
  color: #666;
}

.details-container{
  .name, .zip{
    label{
      color: #7B7B7B;
      font-size: 17px;
    }
  }
}

.md-input{
  font-family: Roboto, Consolas, Menlo, monospace;
  font-size: 17px!important;
  letter-spacing: 0.4px;
  -webkit-font-smoothing: antialiased;
}
@media only screen and (max-width: 760px),
screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
{
  .creditCard{
    min-width: 100%;
  }

  .cardExpiry {
    margin-left: 15px;
  }

  .cardCVC {
    margin-left: 15px;
  }
}



</style>