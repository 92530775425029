<template>
    <div class="md-layout md-alignment-top-left">
        <md-card
                v-if="!showCreditCardForm && !showBankAccountForm"
                class="md-layout-item"
        >

           
               
                    <div class="credit_card"
                            @click="showCreditCardForm = true"
                            @mouseover="creditCheck = true"
                            @mouseleave="creditCheck = false"
                    >
                     
                            <md-icon>credit_card</md-icon>
                            <div class="item-text">Add credit or debit card</div>
                       
                    </div>
                
                      <div class="bank" 
                            @click="showBankAccountForm=true"
                            @mouseover="bankCheck = true"
                            @mouseleave="bankCheck = false"
                    >
                            
                            <md-icon>account_balance</md-icon>
                            <div class="item-text">Add a bank account</div>
                            
                    </div>
              
           
        </md-card>
        <credit-card-form
                v-if="showCreditCardForm"
                class="md-layout-item"
                @cardCreated="paymentMethodCreated"
        ></credit-card-form>
        <bank-account-form
                class="md-layout-item"
                v-if="showBankAccountForm"
                @accountCreated="paymentMethodCreated"
        ></bank-account-form>
    </div>

</template>

<script>
    // import {mapGetters} from 'vuex'
    import CreditCardForm from './CreditCardForm'
    import BankAccountForm from './BankAccountForm'
    export default {
        name: "NewPaymentMethodForm",
        components: {CreditCardForm, BankAccountForm},
        props: {
            redirectOnSubmit: {type: Boolean, default: true}
        },
        data() {
            return {
                creditCheck: false,
                bankCheck: false,
                showCreditCardForm: true,
                showBankAccountForm: false,
            }
        },
        methods: {
            paymentMethodCreated(){
                this.$emit('paymentMethodCreated')
                if(this.redirectOnSubmit){
                    this.$router.push({name: 'settings.payment-methods'})
                }
            }
        }

    }
</script>

<style lang="scss" scoped>

    .md-list-item-content>.md-icon:first-child{
        margin-right: 5px;
    }
    .add-payment .md-alignment-top-left{
        margin-top: 0px;
    }
    .md-card-content:last-of-type{
        padding-bottom: 14px;
    }
   
   .md-app-content .md-card{
       margin-left: 0px;
       margin-top: 26px;
   }

    .item-text{
           white-space: nowrap;
       }

   .credit_card{
       padding-top: 48px;
       float: left;
       width: 50%;
       text-align: center;
       padding-bottom: 30px;
       height: 100%;
       display: block;
       
       .md-icon{
           font-size: 46px!important;
           padding-bottom: 30px;
           text-align: center;
       }
     
      
   }
    .credit_card:hover{
        background-color: #eee;
        cursor: pointer;
    }

     .bank:hover{
        background-color: #eee;
        cursor: pointer;
    }
   .bank{
        float: left;
        padding-top: 48px;
        padding-bottom: 30px;
        width: 50%;
        text-align: center;
        .md-icon{
            padding-bottom: 30px;
            font-size: 42px!important;
            text-align: center;
        }
        border-left: 1px solid #eee;
   }
   
</style>
